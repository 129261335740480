/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgb(80, 80, 80);
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border: 2px solid transparent;
  transition: all 0.3s ease-in;
  background-color: rgb(119, 119, 119);
}
